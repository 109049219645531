import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { BaseRequestService } from './base.service';
import { CommonService } from './common.services';
import { LoaderService } from './loader.service';
import { MyToastrService } from './toastr.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    public isAuthenticated = false;
    currentUser: any;
    tmpData: any;
    isDomain: boolean;
    domainName = '';
    constructor(readonly router: Router, private cs: CommonService,
        public baseRequestService: BaseRequestService,
        readonly location: Location,
    ) {
    }

    getSessionSettings(): void {
        const token = localStorage.getItem('app-token');
        if (token && token.length) {
            this.baseRequestService.authHeader = new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Authorization': `Bearer ${token}`
            });
            this.isAuthenticated = true;
            this.router.navigateByUrl('/manage');
        }
    }

    private afterLogin(result: any, shouldCheckPath: boolean): void {
        this.isAuthenticated = true;
        this.currentUser = result;
        const path: string | null = localStorage.getItem('path');
        const pathLocation = (path && (result && result.permissions &&
            (result.permissions.includes === '' && result.permissions.includes === '*'))) ? path : '';
        if (!shouldCheckPath || this.location.path() === '' || this.location.path() === '/') {
            this.router.navigateByUrl('/manage').then(() => console.log('redirecting to company'));
        } else if (this.location.path() === '/sign-in' && pathLocation !== '' || this.location.path().indexOf('/me') > -1) {
            this.router.navigateByUrl(pathLocation).then(() => console.log('redirected to stored path'));
        } else {
            this.router.navigateByUrl('/manage').then(() => console.log('redirecting to company'));
        }
    }

    afterLogout(): void {
        this.isAuthenticated = false;
        this.currentUser = undefined;
        localStorage.clear();
        if (this.router.url.indexOf('resetpassword') > -1) {
            console.log('showing reset password. no redirect');
        } else {
            setTimeout(() => {
                this.router.navigateByUrl('/sign-in').then(() => console.log('redirected to login'));
            }, 500);
        }
    }
    checkSession(): void {
        let header = new HttpHeaders();
        if (this.domainName && this.isDomain) {
            localStorage.setItem('cid', btoa(this.domainName));
            header = new HttpHeaders({  customerid: this.domainName });
          }
        this.baseRequestService.doRequest(this.baseRequestService.DAPI + `/api/ro_master/`, 'get', null, null, header).subscribe(
            (result: any) => {
                this.tmpData = result.msg;
                if (this.tmpData === 'Already logged in' || result) {
                    const shouldCheckPath = (!this.tmpData.isNewSetup);
                    this.afterLogin(this.tmpData, shouldCheckPath);
                } else {
                    this.afterLogout();
                }
            }, (error: any) => {
                if (error.ok === false) {
                    if (error.status === 401) {
                        if (!error.error.url) {
                            //   this.toast.sToast('error', 'Redirect URL not found. Contact Admin.');
                        } else {
                            const path = this.location.path();
                            if (path === '' || path === '/' || path === '/sign-in' || path.indexOf('/me') > -1) {
                                if (error.error.deploymentModel === 'CyberCNS') {
                                    this.isDomain = true;
                                } else {
                                    window.location = error.error.url;
                                }
                            }
                        }
                        this.isAuthenticated = false;
                        this.currentUser = undefined;
                    } else if (error.status === 403) {
                        console.log(error.error);
                        localStorage.removeItem('cid');
                        this.isAuthenticated = false;
                        this.currentUser = undefined;
                    } else {
                        console.log('Check Session error came..');
                    }
                } else {
                    this.afterLogout();
                    console.log('Check Session error came..');
                }
            }
        );
    }
    logout(): void {
        this.baseRequestService.doRequest('/api/logout', 'get').subscribe((re: any) => {
            this.isAuthenticated = false;
            this.currentUser = undefined;
            localStorage.clear();
            // this.logoutWindow = window.open(re.url, 'CCNS Logout', 'width=200, height=100');
            // setTimeout(() => { window.location.href = `/`; this.logoutWindow.close(); }, 5000);
            // this.afterLogout();
            window.location.href = `${re.url}`;
        } , (err: any) => {
            // this.logoutWindow = window.open(err.error.url, 'CCNS Logout', 'width=200, height=100');
            // setTimeout(() => { window.location.href = `/`; this.logoutWindow.close(); }, 5000);
            // this.afterLogout();
            this.isAuthenticated = false;
            this.currentUser = undefined;
            localStorage.clear();
            window.location.href = `${err.error.url}`;
        });
        }
}
